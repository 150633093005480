/*
 * @Author: amoshong
 * @Date: 2019-03-07 17:15:25
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-05-28 16:38:20
 * @tip 一些共享的数据 抽取原则：小块数据，没必要单独出来的
 * @tip 使用vuex管理：1.静态；2.非静态
 */

//todo: 图片地址换正式域名
const IMG_BASE_PATH = 'https://imgcache.qq.com/open_proj/proj_qcloud_v2/platform/market/css/img'

const COOPERATION_TYPE_PARTNER = 1
const COOPERATION_TYPE_PROVIDER = 2

export const state = () => ({
  //静态数据
  page: {
    limit: 10
  },
  //各页面的banner和mainlist配置
  pageConfig: {
    guide: {
      title: '腾讯云IoT生态伙伴招募计划',
      desc: '腾讯云IoT联合物联网产业链上下游生态伙伴，助力产业数字化智能化升级，共建万物互联智能世界，共赢物联网万亿市场。',
      background: `${IMG_BASE_PATH}/rno-3-hero/banner.png`,
      buttonList: [
        {
          name: 'becomeProvider',
          text: '成为服务商',
          params: {
            cooperationType: COOPERATION_TYPE_PROVIDER
          }
        },
        {
          name: 'becomePartner',
          text: '成为合作伙伴',
          params: {
            cooperationType: COOPERATION_TYPE_PARTNER
          }
        }
      ],
      showChosen: false
    },
    solution: {
      title: '方案中心',
      desc: '由腾讯云IoT臻选合作伙伴提供质优解决方案',
      background: `${IMG_BASE_PATH}/rno-3-hero/banner-2.png`,
      showChosen: true,
      chosenTitle: '官方甄选',
      chosenLimit: 4,
      mainTitle: '精选方案',
      mainLimit: 12,
      mainEmptyTxt1: '没有符合条件的解决方案',
      mainEmptyTxt2: '没有符合条件解决方案，请尝试更改筛选条件',
    },
    module: {
      title: '硬件中心',
      desc: '由腾讯云IoT臻选合作伙伴提供质优硬件产品',
      background: `${IMG_BASE_PATH}/rno-3-hero/banner-1.png`, 
      chosenTitle: '官方甄选',
      showChosen: true,
      chosenLimit: 4,
      mainTitle: '精选硬件',
      mainLimit: 12,
      mainEmptyTxt1: '没有符合条件的硬件',
      mainEmptyTxt2: '没有符合条件的硬件，请尝试更改筛选条件',
    },
    provider: {
      title: '服务商中心',
      desc: '腾讯云IoT官方推荐服务商广场',
      background: `${IMG_BASE_PATH}/rno-3-hero/banner-4.png`, 
      chosenTitle: '官方优选',
      showChosen: true,
      chosenLimit: 4,
      mainTitle: '服务商列表',
      mainLimit: 10,
      mainEmptyTxt1: '没有符合条件的服务商',
      mainEmptyTxt2: '没有符合条件的服务商，请尝试更改筛选条件',
    },
    partner: {
      title: '伙伴中心',
      desc: '腾讯云IoT官方推荐合作伙伴广场',
      background: `${IMG_BASE_PATH}/rno-3-hero/banner-3.png`, 
      chosenTitle: '官方优选',
      showChosen: true,
      chosenLimit: 4,
      mainTitle: '合作伙伴列表',
      mainLimit: 10,
      mainEmptyTxt1: '没有符合条件的合作伙伴',
      mainEmptyTxt2: '没有符合条件的合作伙伴，请尝试更改筛选条件',
    },
  },
  moduleTypeList: ['全部', '模组', '芯片', '硬件设备'],
  newsTypeList: ['全部', '合作动态', '官方公告', '成功案例', '官方活动'],
  slideTextList: [
    {
      title: '腾讯云IoT生态伙伴招募',
      text: ['招募物联网产业链上下游生态伙伴，共赢物联网万亿市场'],
      needSeeInfo: '加入我们'
    },
    {
      title: '物联网开发平台 IoT Explorer',
      text: ['高效、便捷的物联网应用开发服务，支持您的物联网项目快速落地'],
      needSeeInfo: '立即使用'
    },
    {
      title: '物联网通信 IoT Hub',
      text: ['安全、稳定、高效的物联网通信连接平台'],
      needSeeInfo: '立即选购'
    },
    // {
    //   title: '物联网设备身份认证 TID',
    //   text: ['多安全等级、跨平台、资源占用少的物联网设备身份认证服务'],
    //   needSeeInfo: '立即选购'
    // }
  ],
  guideFlowIntro: {
    title: '加入流程',
    list: [
      {
        title: '填写信息',
        img: `${IMG_BASE_PATH}/market-join-process/1.svg`
      },
      {
        title: '官方审核',
        img: `${IMG_BASE_PATH}/market-join-process/2.svg`
      },
      {
        title: '签署协议',
        img: `${IMG_BASE_PATH}/market-join-process/3.svg`
      },
      {
        title: '开展合作',
        img: `${IMG_BASE_PATH}/market-join-process/4.svg`
      }
    ]
  },
  guidePartnerIntro: {
    title: '为什么要成为腾讯云IoT生态伙伴',
    list: [
      {
        title: '产品赋能',
        desc: '一站式智能化服务，一次接入即可打通腾讯产品和内容生态体系',
        img: `${IMG_BASE_PATH}/rno-1-media-panel/1.svg`
      },
      {
        title: '营销推广',
        desc: '全方位对接腾讯庞大市场流量资源，提高伙伴竞争力和影响力',
        img: `${IMG_BASE_PATH}/rno-1-media-panel/2.svg`
      },
      {
        title: '创投支持',
        desc: '对接投资并购部，获得投资机会，纳入腾讯生态体系',
        img: `${IMG_BASE_PATH}/rno-1-media-panel/3.svg`
      },
      {
        title: '合作商机',
        desc: '共享腾讯海量商机，联合参与项目招标，共同打造标杆项目',
        img: `${IMG_BASE_PATH}/rno-1-media-panel/4.svg`
      },
      {
        title: '服务支持',
        desc: '提供完善的测试认证服务，提高联合方案质量，提升客户满意度',
        img: `${IMG_BASE_PATH}/rno-1-media-panel/5.svg`
      }
    ]
  },
  guidePartnerTypeIntro: {
    title: '招募对象',
    providerTypList: [
      {
        title: '综合服务商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-1.svg`
      },
      {
        title: '元器件服务商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-2.svg`
      },
      {
        title: '硬件终端服务商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-3.svg`
      },
      {
        title: '解决方案服务商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-4.svg`
      }
    ],
    partnerTypeList: [
      {
        title: '通讯器件制造商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-5.svg`
      },
      {
        title: '硬件终端设备商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-3.svg`
      },
      {
        title: '解决方案商',
        img: `${IMG_BASE_PATH}/market-object-panel/icon-4.svg`
      }
    ]
  },
  guideBottom: {
    title: '立即成为生态伙伴，共赢物联网万亿市场',
    buttonList: [
      {
        name: 'becomeProvider',
        text: '成为服务商',
        params: {
          cooperationType: COOPERATION_TYPE_PROVIDER
        }
      },
      {
        name: 'becomePartner',
        text: '成为合作伙伴',
        params: {
          cooperationType: COOPERATION_TYPE_PARTNER
        }
      }
    ]
  },
  guideUrls: {
    becomeProvider: {
      sid: '6768739',
      hash: '1f67'
    },
    becomePartner: {
      sid: '6768799',
      hash: '912a'
    }
  },
  //非静态数据
  currentPage: 'home',
  typeList: {
    //typelist component 相关
    currentType: 0
  },
  partnerPage: {
    currentMenuIndex: 0
  }
})

export const actions = {
  async setCurrentType({ commit, state }, currentType) {
    commit('setCurrentType', currentType)
  },
  async setCurrentPage({ commit, state }, page) {
    commit('setCurrentPage', page)
  },
  async setPartnerMenu({ commit, state }, index) {
    commit('setPartnerMenu', index)
  },
  async setModuleCategoryConfig({ commit, state }, obj) {
    commit('setModuleCategoryConfig', obj)
  }
}

export const mutations = {
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setCurrentType(state, type) {
    state.typeList.currentType = type
  },
  setPartnerMenu(state, index) {
    state.partnerPage.currentMenuIndex = index
  },
  setModuleCategoryConfig(state, obj) {
    state.pageConfig.module.categoryConfig.push(obj)
  }
}
