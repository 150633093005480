import { getProperty } from '../util/objHandle.js'

export function getLoginInfo (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/portalLogin',
			dataType: 'json',
			data: {
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['userInfo'] = getProperty(data, 'userInfo', null)
		}
	}
}

export function logOut (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/portalLogout',
			dataType: 'json',
			data: {
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['loginout'] = data
		}
	}
}

export default {
	getLoginInfo,
	logOut,
	// 处理错误码
	errorCodeMap: (data = {}) => {
		let map = {
			'-3000': '请登录'
		}

		return {
			code: data.code,
			errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试'
		}
	}
}
