//登录相关
import {
	getData
} from 'requestModel'
import api from '../api/apiLogin'

export const state = () => ({
})

export const actions = {
	async getLogin ({
		commit
	}, params = {}) {
		let ret = await getData({
			api,
			dataset: ['getLoginInfo'],
			params: {}
		})

		return ret
	},
	async loginOut ({
		commit
	}, params = {}) {
		let ret = await getData({
			api,
			dataset: ['logOut'],
			params: {}
		})

		return ret
	},
}

export const mutations = {
}
