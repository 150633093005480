/*
 * @Author: amoshong
 * @Date: 2019-02-28 10:26:57
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-05-01 14:51:26
 * @tips 拆分规则：根据业务平级（任何页面都不属于谁，都是平级的独立个体）
 * @tips 路由文件名全小写
 */

export default [{
	path: '/',
	redirect: '/index'
},
{
	path: '/index',
	component: () => import('../pages/home/index'),
	name: 'home'
},
{
	path: '/solutionlist',
	component: () => import('../pages/solutionlist/index'),
	name: 'solutionlist'
},
{
	path: '/solutiondetail/:id',
	component: () => import('../pages/solutiondetail/index'),
	name: 'solutiondetail'
},
{
	path: '/partnerlist',
	component: () => import('../pages/partnerlist/index'),
	name: 'partnerlist'
},
{
	path: '/partnerdetail/:id',
	component: () => import('../pages/partnerdetail/index'),
	name: 'partnerdetail'
},
{
	path: '/partnerdetailmobile/:id',
	component: () => import('../pages/partnerdetailmobile/index'),
	name: 'partnerdetailmobile'
},
{
	path: '/modulelist',
	component: () => import('../pages/modulelist/index'),
	name: 'modulelist'
},
{
	path: '/newslist',
	component: () => import('../pages/newslist/index'),
	name: 'newslist'
},
{
	path: '/guide',
	component: () => import('../pages/guide/index'),
	name: 'guide'
},
{
	path: '/providerlist',
	component: () => import('../pages/providerlist/index'),
	name: 'providerlist'
},
{
	path: '/providerdetail/:id',
	component: () => import('../pages/providerdetail/index'),
	name: 'providerdetail'
}
]
