export function getNewsList (params) {
	return {
		dataMap: {
			type: 'meteor',
			flag1: 5,
			flag2: 1,
			noDefinitelyNeed: true
		},
		handlerDataFun (data, ret = {}) {
			ret.list = data.news
		}
	}
}

export default {
	getNewsList,
	// 处理错误码
	errorCodeMap: (data = {}) => {
		let map = {
			'-3000': '请登录'
		}

		return {
			code: data.code,
			errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试'
		}
	}
}
