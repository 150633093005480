<template>
  <div id="app">
    <header-box v-if="needHeaderFooter"></header-box>
    <router-view></router-view>
    <footer-box v-if="needHeaderFooter"></footer-box>
    <footer-box-mobile></footer-box-mobile>
  </div>
</template>

<script>
import HeaderBox from '_c/header/Index.vue'
import FooterBox from '_c/FooterBox'
import FooterBoxMobile from '_c/FooterBoxMobile'
import ConnectionBox from '_c/ConnectionBox'
export default {
  name: 'App',
  computed: {
    needHeaderFooter() {
      return this.$router.history.current.name != 'partnerdetailmobile'
    },
  },
  components: {
    HeaderBox,
    FooterBox,
    FooterBoxMobile
  },
}
</script>

<style></style>
