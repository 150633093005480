/*
 * @Author: amoshong
 * @Date: 2019-04-09 11:20:04
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-04-17 17:34:18
 */

import xss from 'xss'

/**
 *
 * @function 统一跳转处理
 * @param page 具体页面
 * @param deep 刷页面与否
 * @param page search参数
 * @returns undefined
 */

const jump = (vue, pageOptions = {
	page: '',
	deep: false,
	pageParmas: null,
	anchor: ''
}) => {
	let baseUrl = window.location.pathname.match(/^\/\w+/g)[0]
	let pageUrl = `/${pageOptions.page}`

	if (pageOptions.pageParmas) {
		pageUrl += '?'
		for (let parma in pageOptions.pageParmas) {
			pageUrl += `${parma}=${pageOptions.pageParmas[parma]}&`
		}

		pageUrl = pageUrl.substr(0, pageUrl.length - 1)
	}
	if (pageOptions.anchor) {
		pageUrl += pageOptions.anchor
	}
	if (pageOptions.deep) {
		window.location.href = `${baseUrl}${pageUrl}`
	} else {
		vue.$router.push(pageUrl)
	}
}

/**
 *
 * @function 获取url上的参数
 * @param parma 参数名 String
 * @returns 参数值
 */
const getUrlParma = (parma) => {
	try {
		let parmaUrl = window.location.search

		if (!parmaUrl) { return }

		parmaUrl = parmaUrl.substr(1).split('&')

		let strIdx = 0
		let result = ''
		parmaUrl.forEach(v => {
			strIdx = v.indexOf('=')
			parma === v.substr(0, strIdx) && (result = v.substr(++strIdx))
		})

		result = xss(decodeURIComponent(result))

		return result
	} catch (e) { }
}

export {
	jump,
	getUrlParma
}
