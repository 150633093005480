import { getProperty } from 'node-ssr/marketv2/src/util/objHandle'
import { getData } from 'requestModel'
import api from '../api/apiPartner'

export const state = () => ({
  mainList: {
    //搜索列表
    total: 0,
    list: [],
  },
  chosenList: {
    //优选列表
    total: 0,
    list: [],
  },
  partnerDetail: {}, //服务商详情
  solutionList: {
    //解决方案
    total: 0,
    list: [],
  },
  terminalList: {
    //硬件终端
    total: 0,
    list: [],
  },
  deviceList: {
    //元器件
    total: 0,
    list: [],
  },
  recommendProviders: [], //推荐列表

  integratedProviderList: [],
  homeProviderList: {},
  homePartnerList: {},
})

export const actions = {
  async getPartnerList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getPartnerList'],
      params: {
        cooperationType: 1,
        extType: 'INSIDE_SEARCH',
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setPartnerListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },

  async getBestPartnerList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getBestPartnerList'],
      params: {
        cooperationType: 1,
        extType: 'PREFERRED_SEARCH',
        offset: 0,
        limit: 4,
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setBestPartnerListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },

  async getSolutionList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionList'],
      params: {
        ExtType: 'SP_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setSolutionList', ret.listData)
    } else {
      ret = null
    }

    return ret
  },

  async getTerminalList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getTerminalList'],
      params: {
        ActionType: 'HARDWARE_MODULE_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setTerminalList', ret.listData)
    } else {
      ret = null
    }
    return ret
  },

  async getDeviceList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getDeviceList'],
      params: {
        ActionType: 'COMPONENT_MODULE_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setDeviceList', ret.listData)
    } else {
      ret = null
    }
    return ret
  },
  async getIntegratedProviderList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getIntegratedProviderList'],
      params: {
        ...params,
      },
    })
    if (getProperty(ret, 'listData', null)) {
      commit('setIntegratedProviderList', ret.listData)
    } else {
      ret = null
    }

    if (getProperty(ret, 'listData', null)) {
      commit('setPartnerDetail', ret.listData)
    } else {
      ret = null
    }
    return ret
  },

  async getPartnerDetail({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getPartnerDetail'],
      params: {
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setPartnerDetail', ret.listData)
    } else {
      ret = null
    }
    return ret
  },

  async getRecommendProviders({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getRecommendProviders'],
      params: {
        ...params,
      },
    })

    if (getProperty(ret, 'listData', null)) {
      commit('setRecommendProviders', ret.listData)
    } else {
      ret = null
    }
    return ret
  },
  async getHomeProviderList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getPartnerList'],
      params: {
        CooperationType: 1,
        ExtType: 'TOP_SEARCH',
        ...params,
      },
    })
    if (getProperty(ret, 'listData', null)) {
      commit('setHomeProviderList', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
}

export const mutations = {
  setPartnerListData(state, listData) {
    state.mainList.list = listData.list || []
    state.mainList.total = listData.total
  },
  setBestPartnerListData(state, listData) {
    state.chosenList.list = listData.list
    state.chosenList.total = listData.total
  },
  setPartnerDetail(state, listData) {
    state.partnerDetail = listData.data
  },
  setSolutionList(state, listData) {
    state.solutionList.list = (listData && listData.list) || []
    state.solutionList.total = (listData && listData.total) || 0
  },
  setTerminalList(state, listData) {
    state.terminalList.list = (listData && listData.list) || []
    state.terminalList.total = (listData && listData.total) || 0
  },
  setDeviceList(state, listData) {
    state.deviceList.list = (listData && listData.list) || []
    state.deviceList.total = (listData && listData.total) || 0
  },
  setRecommendProviders(state, listData) {
    state.recommendProviders = listData.list
  },
  setIntegratedProviderList(state, list) {
    state.integratedProviderList = list
  },
  setHomeProviderList(state, listdata) {
    state.homeProviderList = listdata.providerList || {}
    state.homePartnerList = listdata.partnerList || {}
  },
}
