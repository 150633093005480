/*
 * @Author: amoshong
 * @Date: 2019-02-28 11:49:24
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-04-12 17:09:25
 * @tips 对象的处理模块
 */

let _ = require('lodash')

/**
 *
 * @function 获取对象属性的方法，可以获取子孙属性，获取不到可以定义默认值
 * @param {...} obj, property.property..., defaultvalue
 * @returns result
 */
const getProperty = (...params) => {
  return _.get(...params)
}

export { getProperty }
