import Vue from 'vue'
import {
	createStore
} from './store'
import createRouter from './router/index'
import {
	sync
} from 'vuex-router-sync' // 把当VueRouter状态同步到Vuex中
import {
	initModel
} from 'requestModel'
import modelConfig from './api/public.config'

import App from './App.vue'

export function createApp (context) {
	const store = createStore()
	const router = createRouter(context)

	// 同步路由状态到store中
	sync(store, router)
	//注册数据模块
	modelConfig.request = context && context.request;
	initModel(modelConfig)

	const app = new Vue({
		store,
		router,
		render: h => h(App)
	})

	return {
		app,
		store,
		router
	}
}
