/*
 * @Author: amoshong
 * @Date: 2019-03-01 17:07:46
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-04-12 17:09:43
 * @tip 用于client 和 server端不兼容的处理
 */


let _ = require('lodash')

/**
 *
 * @function 获取对象属性的方法，可以获取子孙属性，获取不到可以定义默认值
 * @param {...} obj, property.property..., defaultvalue
 * @returns result
 */
const getProperty = (...parmas) => {
	return _.get(...parmas)
}

/**
 *
 * @function 获取window对象中的方法
 * @param obj, propertys, parmasArr
 * @returns undefined
 */
const callWindowProperty = (obj, propertys, parmasArr) => {
	let winFun;
	if (typeof window !== 'undefined') {
		winFun = _.get(obj, propertys, null)
		winFun && winFun(...parmasArr)
	}
}

export {
	callWindowProperty
}
