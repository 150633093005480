<template>
<div class="concat">
	<div class="concat-main">
		<div class="concat-item">
			<div class="mpc-concat-title product-icon-service">联系人</div>
			<p class="mpc-concat-text">物联网市场商务经理</p>
		</div>
		<div class="concat-item">
			<div class="mpc-concat-title product-icon-qq">联系客服 </div>
			<p class="mpc-concat-text"><a :href="qqAddr" target="_blank">{{qqNum}}</a></p>
		</div>
		<div class="concat-item">
			<div class="mpc-concat-title product-icon-email">联系邮箱 </div>
			<p class="mpc-concat-text">
				<a href="mailto:iotmarket@tencent.com?subject=您的问题&cc=抄送人邮箱&subject=主题&body=内容">iotmarket@tencent.com</a>
			</p>
		</div>
	</div>
</div>
</template>
<script>
  export default {
      data(){
          return {
						qqNum :1692228780,
						qqAddr:''
					}
      },
			methods:{
				isPc(){
					if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            return false
          }else {
						return true
          }
				}
			},
			mounted(){
				if(this.isPc()){
					this.qqAddr = `http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=${this.qqNum}`
				}else{
					this.qqAddr = `mqqwpa://im/chat?chat_type=wpa&uin=${this.qqNum}`
				}
			}
  }

</script>
