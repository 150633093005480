/*
 * @Author: amoshong
 * @Date: 2019-03-01 10:59:39
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-03-07 20:05:05
 * @tips 搜索框数据共享
 */

export const state = () => ({
	text: ''
})

export const actions = {
	setText ({ commit, state }, value) {
		commit('setText', value)
	}
}

export const mutations = {
	setText (state, value) {
		state.text = value
	}
}
