import { getProperty } from '../util/objHandle.js'

export function getModuleList(params = {}) {
  return {
    dataMap: {
      url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeModuleList',
      dataType: 'json',
      data: {
        action: 'DescribeModuleList',
        actionType: 'FRONT_LIST',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = {
        list: getProperty(data, 'data.moduleList', []),
        total: getProperty(data, 'data.total', 0),
      }
    },
  }
}

export default {
  getModuleList,
  // 处理错误码
  errorCodeMap: (data = {}) => {
    let map = {
      '-3000': '请登录',
    }

    return {
      code: data.code,
      errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试',
    }
  },
}
