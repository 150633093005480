import { getData } from 'requestModel'
import api from '../api/apiUserInfo'
let cookie_util = require('js-cookie')

export const state = () => ({
  userInfo: {},
  settleStatusData: {}
})

export const actions = {
  async getUserInfo({ commit }, params = {}) {
    let uin = cookie_util.get('uin')

    if (!uin) {
      return null
    }
    
    let ret = await getData({
      api,
      dataset: ['getUserInfo'],
      params: {
        Uin: uin,
        SubAccountUin: uin
      }
    })

    if (ret && ret.userInfo) {
      commit('setUserInfo', ret.userInfo)
    } else {
      ret = null
    }
    return ret
  },
  async getSettleStatus({ commit }, params = {}) {
    let uin = cookie_util.get('uin')
    
    if (!uin) {
      return null
    }

    //去掉无效字符
    uin = uin.replace(/^o0*f/, '')
    //todo 测试数据要删掉
    //uin = '12222' //settleStatus: 1，已对接未入驻
    //uin = '12345678111' //settleStatus: 2，入驻审核中
    //uin = '753446070' //settleStatus: 3，入驻完成

    let ret = await getData({
      api,
      dataset: ['getSettleStatus'],
    })

    if (ret && ret.statusData) {
      commit('setSettleStatusData', ret.statusData)
    } else {
      ret = null
    }
    return ret
  }
}

export const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setSettleStatusData(state, statusData) {
    state.settleStatusData = statusData
  }
}
