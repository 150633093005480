import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import routes from './routes' //导入router 映射规则
import { callWindowProperty } from '../util/forBundle'

//每次import都创建一个新的router 避免内存溢出
export default (context = {}) => {
	let routerParmas = {
		routes,
		base: 'marketv2',
		mode: 'history' //改为history模式方便服务端渲染做SEO
	}

	try {
		let baseUrl = ''
		if (context.realUrl === undefined) { //客户端也需要用到
			context.realUrl = window.location.pathname
		}
		baseUrl = context.realUrl.match(/^\/\w+/g)
		baseUrl && (baseUrl = baseUrl[0].substr(1))
		routerParmas.base = baseUrl
	} catch (e) { }

	let router = new Router(routerParmas)

	router.beforeEach((to, from, next) => {
		if (typeof window !== 'undefined') {
			callWindowProperty(window, 'scrollTo', [0, 0])
		}
		next()
	})

	return router
}
