<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import { jump, getUrlParma } from '../../util/jumpPage'
const seletct_text_map = {
  modulelist: '硬件',
  solutionlist: '解决方案',
  providerlist: '服务商',
  partnerlist: '合作伙伴',
}
export default {
  name: 'headerBox',
  data() {
    return {
      searchInput: '',
      showSearchBox: false,
      showMask: false,
      searchInputStatus: true,
      loginStatus: false,
      loadLogined: false,
      showCenterStatus: false,
      showLoginOutStatus: false,
      userName: '',
	    isShowProducts: false,
	    showSelect: false,
	    selectText: '硬件',
	    selectValue: 'modulelist',
    }
  },
  computed: {
    ...mapState('commonData', {
      currentPage: (s) => s.currentPage,
    }),
    ...mapState('dataUserInfo', {
      statusData: (s) => s.settleStatusData
    }),
    placeholder() {
      return this.currentPage === 'home' ? '请输入搜索内容' : `请输入${seletct_text_map[this.currentPage]}相关搜索内容`
    }
  },
  mounted() {
    getUrlParma('search') && this.setInputText(getUrlParma('search'))
    this.getSettleStatus()
    this.loadLogin()
    document.onclick = () => {
      if(this.showSelect) this.showSelect = false
    }
  },
  methods: {
    ...mapActions('dataLogin', ['getLogin', 'loginOut']),
    ...mapActions('dataUserInfo', ['getSettleStatus']),
    setInputText(v = '') {
      this.searchInput = v
    },
    go_page(page) {
      this.setInputText('')

      jump(this, {
        page,
        deep: true,
      })
    },
    //search
    trigger_input(e) {
      this.searchInput = e.target.value
    },
    change_select_status() {
      this.showSelect = !this.showSelect
    },
    change_select(currentPage) {
      this.selectText = seletct_text_map[currentPage]
      this.selectValue = currentPage
    },
    seach_input_submit() {
      // this.hide_searchbox_trigger()

      let page = this.currentPage === 'home' ? this.selectValue : this.currentPage

      if (page.indexOf('detail') > -1) {
        page = page.substr(0, page.indexOf('detail')) + 'list'
      }

      jump(this, {
        page,
        deep: true,
        pageParmas: {
          search: this.searchInput,
        },
        anchor: '#category'
      })
    },
    show_searchbox_trigger() {
      this.showSearchBox = true
      this.showMask = true
      this.$refs.qcIptSearch.focus()
    },
    hide_searchbox_trigger() {
      this.showSearchBox = false
      this.showMask = false
    },
    jumpToLoginUrl() {
      window.location.href =
        '//cloud.tencent.com/login?s_url=' +
        encodeURIComponent(window.location.href)
    },
    jumpToRegisterUrl() {
      window.location.href =
        '//cloud.tencent.com/register?s_url=' +
        encodeURIComponent(window.location.href)
    },
    show_center_float() {
      this.showCenterStatus = true
    },
    hide_center_float() {
      this.showCenterStatus = false
    },
    show_loginout_float() {
      this.showLoginOutStatus = true
    },
    hide_loginout_float() {
      this.showLoginOutStatus = false
    },
    async loadLogin() {
      let logRet = await this.getLogin()

      this.loadLogined = true
      if (logRet && logRet.userInfo) {
        this.loginStatus = true
        this.userName = logRet.userInfo.nickname
      } else {
        this.loginStatus = false
      }
    },
    async login_out() {
      this.loginOut().then(
        () => {
          window.location.href =
            '//cloud.tencent.com/login?s_url=' +
            encodeURIComponent(window.location.href)
        },
        () => {}
      )
    },
	toggleShowProducts(isShow) {
		this.isShowProducts = isShow
	}
  },
}
</script>
