import { getProperty } from '../util/objHandle.js'
import { getData } from 'requestModel'
import api from '../api/apiSolution'

const formatData = (list) => {
  let tempList

  if (Array.isArray(list)) {
    tempList = list.slice(0)
    tempList.forEach((v, i) => {
      tempList[i].attrList = v.attrs.split(',')
    })
  } else {
    tempList = { ...list }
    tempList.attrList = tempList.attrs.split(',')
  }

  return tempList
}

//平级拆分
//按照数据源维度区分 而不是页面级区分
//数据（一般不可定制） view（可定制）
//data => (store.js)control => (.vue)view
//存放state 单纯数据管理
export const state = () => ({
  // 首页用
  categorySolutionList: [],

  // 方案中心用
  chosenList: {
    total: 0,
    list: []
  },
  mainList: {
    total: 0,
    list: []
  },

  // 服务商中心用
  partnersSolutionList: {
    total: 0,
    list: []
  },

  // 方案详情用
  detail: {},

  homeSolutionList: []
})

export const actions = {
  async getListWithCategory({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getCategorySolutionList'],
      params: {
        ...params
      }
    })

    if (getProperty(ret, 'listData.data', null)) {
      commit('setCategorySolutionListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  async getChosenList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionListV2'],
      params: {
        extType: 'CENTER_CHOSEN',
        ...params
      }
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setChosenListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  async getMainList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionListV2'],
      params: {
        extType: 'CENTER_LIST',
        ...params
      }
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setMainListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  async getPartnersSolutionList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionList'],
      params: {
        actionType: 'FRONT_LIST',
        ...params
      }
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setPartnersSolutionList', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  async getSolutionDetail({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionDetailV2'],
      params: {
        ...params
      }
    })

    if (getProperty(ret, 'detail', null)) {
      commit('setDetailData', ret.detail)
    } else {
      ret = null
    }

    return ret
  },
  async getHomeSolutionList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getSolutionListV2'],
      params: {
		extType: 'HOME_TOP',
		offset: 0,
		limit: 8,
        ...params,
      },
	})
	
    if (getProperty(ret, 'listData.list', null)) {
      commit('setHomeSolutionList', ret.listData.list)
    } else {
      ret = null
    }

    return ret
  }
}

export const mutations = {
  setCategorySolutionListData(state, listData) {
    state.categorySolutionList = listData.data
  },
  setChosenListData(state, listData) {
    state.chosenList.list = formatData(listData.list)
    state.chosenList.total = listData.total
  },
  setMainListData(state, listData) {
    state.mainList.list = formatData(listData.list)
    state.mainList.total = listData.total
  },
  setPartnersSolutionList(state, listData) {
    state.partnersSolutionList.list = formatData(listData.list)
    state.partnersSolutionList.total = listData.total
  },
  setDetailData(state, detail) {
    state.detail = formatData(detail)
  },
  setHomeSolutionList(state, listData) {
    state.homeSolutionList = listData
  },
}
