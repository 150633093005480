/*
 * @Author: amoshong
 * @Date: 2019-03-04 19:11:22
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-03-29 20:59:07
 * @tip 二级筛选相关
 */

import { getData } from 'requestModel'
import api from '../api/apiCategorys'

const HANDLE = {
  formatData(listData = []) {
    let scenesMap1 = {}
    let scenesMap2 = {}
    let parentId //for temp
    listData.map((category) => {
      parentId = category.parentId
      if (parentId === '0') {
        scenesMap1[category.id] = category.categoryName
      } else if (parentId !== '0') {
        scenesMap2[parentId] = scenesMap2[parentId] || []
        scenesMap2[parentId].push({
          categoryId: category.id,
          categoryName: category.categoryName
        })
      }
    })

    let sceneTypeList = []
    let categoryList1 = [{ categoryId: '0', categoryName: '全部' }]
    let categoryList2 = [[{ categoryId: '0', categoryName: '全部' }]]

    for (let key in scenesMap1) {
      if (scenesMap2[key] && scenesMap2[key].length) {
        //没有的过滤掉
        sceneTypeList.push({
          key,
          value: scenesMap1[key]
        })
        categoryList1.push({
          categoryId: key,
          categoryName: scenesMap1[key]
        })
        categoryList2.push(scenesMap2[parseInt(key)])
      }
    }

    return {
      sceneTypeList,
      categoryList1,
      categoryList2
    }
  }
}

export const state = () => ({
  categoryList1: [],
  categoryList2: [],
  categoryId1: 0,
  categoryId2: 0,

  // v2的所有 xxxTypeList，item格式都是 {key, value}
  sceneTypeList: [],
  sceneTypeId: 0,

  solutionTypeList: [],
  solutionTypeId: 0,

  serviceTypeList: [],
  serviceTypeId: 0,

  cooperationTypeList: [],
  cooperationTypeId: 0,

  communicationTypeList: [],
  communicationTypeId: 0,

  hardwareTypeList: [],
  hardwareTypeId: 0,

  module: {
    moduleTypeId: 0,
    subTypeId: 0,
    typeList: [],
    subTypeList: [],
  },
})

export const actions = {
  async getCategorys({ commit, state }) {
    let ret = await getData({
      api,
      dataset: ['getCategorys'],
      onError() {},
      params: {}
    })
    if (ret && ret.listData) {
      ret = HANDLE.formatData(ret.listData)
      commit('setData', ret)
      commit('setSceneTypeList', ret)
    } else {
      ret = null
    }

    return ret
  },
  async getCategorysV2({ commit, state }) {
    let ret = await getData({
      api,
      dataset: ['getCategorysV2'],
      onError() {},
      params: {}
    })
    if (ret && ret.dictData) {
      commit('setDictData', ret.dictData)
    } else {
      ret = null
    }

    return ret
  },
  setCategoryId({ commit, state }, obj = {}) {
    commit('setCategoryId', obj)
  },
  setSceneTypeId({ commit, state }, obj = {}) {
    commit('setSceneTypeId', obj)
  },
  setSolutionTypeId({ commit, state }, obj = {}) {
    commit('setSolutionTypeId', obj)
  },
  setTypeId({ commit, state }, obj = {}) {
    commit('setTypeId', obj)
  },
}

export const mutations = {
  setData(state, listData) {
    state.categoryList1 = listData.categoryList1
    state.categoryList2 = listData.categoryList2
  },
  setCategoryId(state, obj) {
    obj.categoryId1 && (state.categoryId1 = obj.categoryId1)
    obj.categoryId2 && (state.categoryId2 = obj.categoryId2)
  },

  //各种 xxxTypeList 一起设置
  setDictData(state, dictData = {}) {
    for (const key in dictData) {
      if (state.hasOwnProperty(key) && dictData[key]) {
        state[key] = dictData[key]
      }
    }
  },

  //应用场景
  setSceneTypeList(state, listData) {
    state.sceneTypeList = listData.sceneTypeList
  },
  setSceneTypeId(state, obj) {
    state.sceneTypeId = +obj.sceneTypeId
  },

  //方案类型
  setSolutionTypeList(state, listData) {
    state.solutionTypeList = listData.solutionTypeList
  },
  setSolutionTypeId(state, obj) {
    state.solutionTypeId = +obj.solutionTypeId
  },
  setTypeId(state, obj) {
    state[obj.type] = +obj.id
  },
}
