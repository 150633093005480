import {
	getData
} from 'requestModel'
import api from '../api/apiSuccessProject'

const formatData = (list) => {
	let tempList;

	tempList = list.slice(0)
	tempList.forEach((v, i) => {
		tempList[i].logo = v.logo.replace(/http\:\/\//, 'https://')
	})

	return tempList
}

export const state = () => ({
	list: [],
	total: 0
})

export const actions = {
	async getSuccessProjectList ({
		commit
	}) {
		let ret = await getData({
			api,
			dataset: ['getSuccessProjectList'],
			onError () {

			},
			//传给api的参数
			params: []
		})
		if (ret && ret.list) {
			commit('setData', ret)
		} else {
			ret = null
		}

		return ret
	}
}

export const mutations = {
	setData (state, value) {
		state.list = formatData(value.list)
		//state.total = value.total
	}
}
