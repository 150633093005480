/*
 * @Author: amoshong
 * @Date: 2019-03-06 15:28:44
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-03-27 17:17:05
 * @tip 轮播图
 */


import {
	getData
} from 'requestModel'
import api from '../api/apiSlide'


const HANDLE = {
	formatData (listData = []) {
		listData.length && listData.forEach((v, i) => {
			listData[i]['image'] = v['image'].replace(/http\:\/\//, 'https://')
		})
		return listData
	}
}

export const state = () => ({
	list: []
})

export const actions = {
	async getSlideList ({
		commit,
		dispatch
	}) {
		let ret = await getData({
			api,
			dataset: ['getSlideList'],
			onError () {

			},
			params: {}
		})

		if (ret && ret.listData) {
			ret.listData = HANDLE.formatData(ret.listData)
			commit('setData', ret.listData)
		} else {
			ret = null
		}

		return ret
	},
	changeImage ({ commit }, idx) {
		commit('changeImage', idx)
	}
}

export const mutations = {
	setData (state, listData) {
		state.list = listData
	},
	changeImage (state, idx) {
		let bl = state.list
		bl.length && bl.forEach((v, i) => {
			bl[i]['image'] = `background-image: url(${v.image});`;
			if (idx == i) {
				bl[i]['image'] += 'z-index:0;'
			} else {
				bl[i]['image'] += 'z-index:-1;'
			}
		})
	}
}
