import { getProperty } from '../util/objHandle.js'

//查询服务商搜索列表
export function getProviderList (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeProviders',
			dataType: 'json',
			data: {
				action: "DescribeProviders",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data.list', null),
				total: getProperty(data, 'data.total', 0)
			}
		}
	}
}

//查询服务商优选列表
export function getBestProviderList (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeProviders',
			dataType: 'json',
			data: {
        action: "DescribeProviders",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data.list', null),
				total: getProperty(data, 'data.total', 0)
			}
		}
	}
}

//查询服务商详情
export function getProviderDetail (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/DescribeProviderDetails',
			dataType: 'json',
			data: {
        action: "DescribeProviderDetails",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				data: getProperty(data, 'data', null),
			}
		}
	}
}

//查询服务商解决方案列表
export function getSolutionList (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/DescribeSolutions',
			dataType: 'json',
			data: {
        action: "DescribeSolutions",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data.list', null),
				total: getProperty(data, 'data.total', 0)
			}
		}
	}
}

//查询服务商硬件终端列表
export function getTerminalList (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/DescribeModuleList',
			dataType: 'json',
			data: {
        action: "DescribeModuleList",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data.moduleList', null),//??参数
				total: getProperty(data, 'data.total', 0)
			}
		}
	}
}

//查询服务商元器件列表
export function getDeviceList (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/DescribeModuleList',
			dataType: 'json',
			data: {
        Action: "DescribeModuleList",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data.moduleList', null),//??参数
				total: getProperty(data, 'data.total', 0)
			}
		}
	}
}

//查询服务商相关推荐列表
export function getRecommendProviders (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/DescribeFrontRecommendProviders',
			dataType: 'json',
			data: {
        Action: "DescribeFrontRecommendProviders",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['listData'] = {
				list: getProperty(data, 'data', null),
			}
		}
	}
}

export default {
	getProviderList,
	getBestProviderList,
	getProviderDetail,
	getSolutionList,
	getTerminalList,
	getDeviceList,
	getRecommendProviders,
	// 处理错误码
	errorCodeMap: (data = {}) => {
		let map = {
			'-3000': '请登录'
		}

		return {
			code: data.code,
			errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试'
		}
	}
}
