//用户信息相关
import { getProperty } from '../util/objHandle.js'

export function getUserInfo (params = {}) {
	return {
		dataMap: {
			url: 'MainDescribeSPServiceProviders',
			dataType: 'json',
			data: {
				action: "DescribeSPServiceProviders",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['userInfo'] = getProperty(data, 'data.list.0', null)
		}
	}
}

export function getSettleStatus (params = {}) {
	return {
		dataMap: {
			url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeProviderSettleStatus',
			dataType: 'json',
			data: {
				action: "DescribeProviderSettleStatus",
				...params
			},
			dcapi: {
				fromId: 211006089,
				toId: 211006088,
				interfaceId: 0
			}
		},
		handlerDataFun (data, ret = {}) {
			ret['statusData'] = getProperty(data, 'data', null)
		}
	}
}

export default {
	getUserInfo,
	getSettleStatus,
	// 处理错误码
	errorCodeMap: (data = {}) => {
		let map = {
			'-3000': '请登录'
		}

		return {
			code: data.code,
			errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试'
		}
	}
}
