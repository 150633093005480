
import { getProperty } from 'node-ssr/marketv2/src/util/objHandle'
import {
	getData
} from 'requestModel'
import api from '../api/apiProvider'

export const state = () => ({
	mainList: {//搜索列表
		total: 0,
		list: []
	},
	chosenList: {//优选列表
		total: 0,
		list: []
	},
	providerDetail: {},//服务商详情
	solutionList: {//解决方案
		total: 0,
		list: []
	},
	terminalList: {//硬件终端
		total: 0,
		list: []
	},
	deviceList: {//元器件
		total: 0,
		list: []
	},
	recommendProviders: [],//推荐列表
})

export const actions = {
	async getProviderList ({
		commit
	}, params = {}) {
		let ret = await getData({
			api,
			dataset: ['getProviderList'],
			params: {
				cooperationType: 2,
				extType: "INSIDE_SEARCH",
				...params
			}
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setProviderListData', ret.listData)
		} else {
			ret = null
		}

		return ret
	},

	async getBestProviderList ({
		commit
	}, params = {}) {

		let ret = await getData({
			api,
			dataset: ['getBestProviderList'],
			params: {
				cooperationType: 2,
				extType: "PREFERRED_SEARCH",
				offset: 0,
      	limit: 4,
				...params
			}
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setBestProviderListData', ret.listData)
		} else {
			ret = null
		}

		return ret
	},

	async getSolutionList ({
		commit
	}, params = {}) {
		let ret = await getData({
			api,
			dataset: ['getSolutionList'],
			params: {
				extType: 'SP_LIST',
				...params
			}
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setSolutionList', ret.listData)
		} else {
			ret = null
		}

		return ret
	},

	async getTerminalList ({
		commit
	}, params = {}) {

		let ret = await getData({
			api,
			dataset: ['getTerminalList'],
			params: {
				ActionType: 'HARDWARE_MODULE_LIST',
				...params
			}
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setTerminalList', ret.listData)
		} else {
			ret = null
		}
		return ret

	},

	async getDeviceList ({
		commit
	}, params = {}) {

		let ret = await getData({
			api,
			dataset: ['getDeviceList'],
			params: {
				ActionType: 'COMPONENT_MODULE_LIST',
				...params
			}
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setDeviceList', ret.listData)
		} else {
			ret = null
		}
		return ret
	},

	async getProviderDetail ({
		commit
	}, params = {}) {

		let ret = await getData({
			api,
			dataset: ['getProviderDetail'],
			params: {
				...params
			}
		})
		if (getProperty(ret, 'listData', null)) {
			commit('setProviderDetail', ret.listData)
		} else {
			ret = null
		}
		return ret

	},

	async getRecommendProviders ({
		commit
	}, params = {}) {

		let ret = await getData({
			api,
			dataset: ['getRecommendProviders'],
			params
		})

		if (getProperty(ret, 'listData', null)) {
			commit('setRecommendProviders', ret.listData)
		} else {
			ret = null
		}
		return ret

	},
}

export const mutations = {
	setProviderListData (state, listData) {
		state.mainList.list = listData.list
		state.mainList.total = listData.total
	},
	setBestProviderListData (state, listData) {
		state.chosenList.list = listData.list
		state.chosenList.total = listData.total
	},
	setProviderDetail (state, listData) {
		state.providerDetail = listData.data
	},
	setSolutionList (state, listData) {
		state.solutionList.list = listData && listData.list || []
		state.solutionList.total = listData && listData.total || 0
	},
	setTerminalList (state, listData) {
		state.terminalList.list = listData && listData.list || []
		state.terminalList.total = listData && listData.total || 0
	},
	setDeviceList (state, listData) {
		state.deviceList.list = listData && listData.list || []
		state.deviceList.total = listData && listData.total || 0
	},
	setRecommendProviders (state, listData) {
		state.recommendProviders= listData.list
  },
}
