//http://tapd.oa.com/topai/markdown_wikis/#1020365802008746245
import { getProperty } from '../util/objHandle.js'

export function getSolutionList(params = {}) {
  return {
    dataMap: {
      url: 'MainDescribeSolutions',
      dataType: 'json',
      data: {
        action: 'DescribeSolutions',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = {
        list: getProperty(data, 'list', null),
        total: getProperty(data, 'total', 0),
      }
    },
  }
}

export function getSolutionListV2(params = {}) {
  return {
    dataMap: {
      url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeSolutions',
      dataType: 'json',
      data: {
        action: 'DescribeSolutions',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = {
        list: getProperty(data, 'data.list', null),
        total: getProperty(data, 'data.total', 0),
      }
    },
  }
}

export function getRecommendSolutionList(params = {}) {
  return {
    dataMap: {
      url:
        '//iot.cloud.tencent.com/api/marketv2/MainDescribeFrontRecommendSolutions',
      dataType: 'json',
      data: {
        action: 'DescribeFrontRecommendSolutions',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = getProperty(data, 'data', null)
    },
  }
}

export function getCategorySolutionList(params = {}) {
  return {
    dataMap: {
      url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeFrontTopSolutions',
      dataType: 'json',
      data: {
        action: 'DescribeFrontTopSolutions',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = data
    },
  }
}

export function getSolutionDetail(params = {}) {
  return {
    dataMap: {
      url: 'MainDescribeSolution',
      dataType: 'json',
      data: {
        action: 'DescribeSolution',
        actionType: 'FRONT',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['detail'] = getProperty(data, 'data', null)
    },
  }
}

export function getSolutionDetailV2(params = {}) {
  return {
    dataMap: {
      url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeSolutionById',
      dataType: 'json',
      data: {
        action: 'DescribeSolutionById',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['detail'] = getProperty(data, 'data', null)
    },
  }
}

export default {
  getCategorySolutionList,
  getRecommendSolutionList,
  getSolutionList,
  getSolutionListV2,
  getSolutionDetail,
  getSolutionDetailV2,
  // 处理错误码
  errorCodeMap: (data = {}) => {
    let map = {
      '-3000': '请登录',
    }

    return {
      code: data.code,
      errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试',
    }
  },
}
