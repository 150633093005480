/*
 * @Author: amoshong
 * @Date: 2019-03-04 19:10:22
 * @Last Modified by: amoshong
 * @Last Modified time: 2019-03-08 17:45:47
 * @tip 二级筛选相关
 */
import { getProperty } from '../util/objHandle.js'

export function getCategorys(params = {}) {
  return {
    dataMap: {
      url: 'MainDescribeCategorys',
      dataType: 'json',
      data: {
        action: 'DescribeCategorys',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['listData'] = getProperty(data, 'data', [])
    },
  }
}

export function getCategorysV2(params = {}) {
  return {
    dataMap: {
      url: '//iot.cloud.tencent.com/api/marketv2/MainDescribeDictList',
      dataType: 'json',
      data: {
        action: 'DescribeDictList',
        ...params,
      },
      dcapi: {
        fromId: 211006089,
        toId: 211006088,
        interfaceId: 0,
      },
    },
    handlerDataFun(data, ret = {}) {
      ret['dictData'] = getProperty(data, 'data', [])
    },
  }
}

export default {
  getCategorys,
  getCategorysV2,
  // 处理错误码
  errorCodeMap: (data = {}) => {
    let map = {
      '-3000': '请登录',
    }

    return {
      code: data.code,
      errorMsg: map[data.code] || data.errorMsg || '系统繁忙，请稍候再试',
    }
  },
}
