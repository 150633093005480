<template>
  <div class="footer">
    <div class="concat-us">
      <div class="concat-title">联系我们</div>
      <div class="taro-img concat-qrcode">
        <img
          class="taro-img__mode-scaletofill"
          src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/platform/market/css/img/footer/download.png"
          alt=""
        />
      </div>
      <div class="concat-qrcode-desc">
        <div class="concat-qrcode-desc-text">欢迎扫描客服专员微信二维码</div>
        <div class="concat-qrcode-desc-text">
          申请加入腾讯云物联网合作伙伴交流群
        </div>
      </div>
      <div class="concat-way">联系QQ： 1692228780</div>
      <div class="concat-way">官方邮箱： iotmarket@tencent.com</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-box-mobile',
}
</script>
