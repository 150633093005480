<template>
  <div class="qc-footer c-footer J-qc-footer none" data-type="cn_zh">
    <div class="c-footer-service">
      <div class="c-footer-inner">
        <ul class="c-footer-service-list">
          <li>
            <a
              href="//cloud.tencent.com/act/event/service-protection#refund"
              rel="nofollow"
              class="c-footer-service-item"
              hotrep="hp.header.adventage.2"
            >
              <span class="icon icon-2"></span>
              <span class="term">5天内无理由退款</span>
            </a>
          </li>
          <li>
            <a
              href="//cloud.tencent.com/act/event/service-protection#filing"
              rel="nofollow"
              class="c-footer-service-item"
              hotrep="hp.header.adventage.3"
            >
              <span class="icon icon-3"></span>
              <span class="term">免费备案</span>
            </a>
          </li>
          <li>
            <a
              href="//cloud.tencent.com/act/event/service-plan#enterprise"
              rel="nofollow"
              class="c-footer-service-item"
              hotrep="hp.header.adventage.4"
            >
              <span class="icon icon-4"></span>
              <span class="term">1V1大客户服务</span>
            </a>
          </li>
          <li>
            <a
              href="//cloud.tencent.com/act/event/service-protection#serviceTime"
              rel="nofollow"
              class="c-footer-service-item"
              hotrep="hp.header.adventage.5"
            >
              <span class="icon icon-5"></span>
              <span class="term">7x24小时服务</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-footer-website">
      <div class="c-footer-inner">
        <ul class="c-grid c-footer-website-columns J-qcFooterNav">
          <li class="c-g-5 xs-24">
            <div class="c-footer-website-group J-qcFooterNavColumn">
              <h3 class="c-footer-website-title J-qcFooterNavColumnTitle">
                <a href="javascript:;">腾讯云计算</a>
              </h3>

              <ul class="c-footer-website-list J-qcFooterNavColumnList">
                <li>
                  <a
                    href="https://cloud.tencent.com/product"
                    hotrep="hp.footer.navigation.compute.chanpin"
                    >产品</a
                  >
                </li>

                <li>
                  <a
                    href="https://market.cloud.tencent.com/"
                    hotrep="hp.footer.navigation.compute.yunshichang"
                    >云市场</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/customer/game?start=1"
                    hotrep="hp.footer.navigation.compute.kehuanli"
                    >客户案例</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/product/events"
                    hotrep="hp.footer.navigation.compute.fabutai"
                    >产品发布台</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/product/ba?from=qcloudProductBa"
                    hotrep="hp.footer.navigation.compute.beian"
                    >网站备案</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/document/product/363"
                    hotrep="hp.footer.navigation.compute.renzhengxinxi"
                    >认证信息</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/document/product/301/2007"
                    hotrep="hp.footer.navigation.compute.xukezheng"
                    >许可证说明</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/document/product/301/11470"
                    hotrep="hp.footer.navigation.compute.shengming"
                    >隐私声明</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/about"
                    hotrep="hp.footer.navigation.compute.guanyu"
                    >关于我们</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="c-g-5 xs-24">
            <div class="c-footer-website-group J-qcFooterNavColumn">
              <h3 class="c-footer-website-title J-qcFooterNavColumnTitle">
                <a href="javascript:;">解决方案</a>
              </h3>

              <ul class="c-footer-website-list J-qcFooterNavColumnList">
                <li>
                  <a
                    href="https://cloud.tencent.com/solution/video"
                    hotrep="hp.footer.navigation.solution.shipin"
                    >视频</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/game"
                    hotrep="hp.footer.navigation.solution.youxi"
                    >游戏</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/finance"
                    hotrep="hp.footer.navigation.solution.jinrong"
                    >金融</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/e-commerce"
                    hotrep="hp.footer.navigation.solution.dianshang"
                    >电商</a
                  >
                </li>

                <li>
                  <a
                    href="https://market.cloud.tencent.com/website/solution?purchasesource=201911-flow-solution"
                    hotrep="hp.footer.navigation.solution.wangzhan"
                    >网站</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/bigdata"
                    hotrep="hp.footer.navigation.solution.dashuju"
                    >大数据</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/face-recognition"
                    hotrep="hp.footer.navigation.solution.renlianheshen"
                    >人脸核身</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/education"
                    hotrep="hp.footer.navigation.solution.zaixianjiaoyu"
                    >在线教育</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/la"
                    hotrep="hp.footer.navigation.solution.xiaochengxu"
                    >微信小程序</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/solution/wx-video"
                    hotrep="hp.footer.navigation.solution.xiaochengxuyinshipin"
                    >微信小程序音视频</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="c-g-5 xs-24">
            <div class="c-footer-website-group J-qcFooterNavColumn">
              <h3 class="c-footer-website-title J-qcFooterNavColumnTitle">
                <a href="javascript:;">资源与社区</a>
              </h3>

              <ul class="c-footer-website-list J-qcFooterNavColumnList">
                <li>
                  <a
                    href="https://cloud.tencent.com/act/free"
                    hotrep="hp.footer.navigation.resource.mianfei"
                    >免费套餐</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/document/guide"
                    hotrep="hp.footer.navigation.resource.rumen"
                    >入门中心</a
                  >
                </li>

                <li>
                  <a
                    href="https://buy.cloud.tencent.com/price"
                    hotrep="hp.footer.navigation.resource.dingjia"
                    >定价中心</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/document/developer-resource"
                    hotrep="hp.footer.navigation.resource.ziyuan"
                    >资源中心</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/edu/learning?ADTAG=gw.bott"
                    hotrep="hp.footer.navigation.resource.edu"
                    >腾讯云大学</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer"
                    hotrep="hp.footer.navigation.resource.shequ"
                    >云+社区</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer/column"
                    hotrep="hp.footer.navigation.resource.zhuanlan"
                    >专栏</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer/ask"
                    hotrep="hp.footer.navigation.resource.wenda"
                    >问答</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer/salon"
                    hotrep="hp.footer.navigation.resource.salon"
                    >沙龙</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="c-g-5 xs-24">
            <div class="c-footer-website-group J-qcFooterNavColumn">
              <h3 class="c-footer-website-title J-qcFooterNavColumnTitle">
                <a href="javascript:;">合作与生态</a>
              </h3>

              <ul class="c-footer-website-list J-qcFooterNavColumnList">
                <li>
                  <a
                    href="https://partners.cloud.tencent.com/"
                    hotrep="hp.footer.navigation.cooperate.huoban"
                    >合作伙伴</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer/partner"
                    hotrep="hp.footer.navigation.cooperate.partner"
                    >合作伙伴学院</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/partner/cps"
                    hotrep="hp.footer.navigation.cooperate.tuiguang"
                    >推广奖励</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/benefit"
                    hotrep="hp.footer.navigation.cooperate.gongyi"
                    >云+公益</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/campus"
                    hotrep="hp.footer.navigation.cooperate.xiaoyuan"
                    >云+校园</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/plan"
                    hotrep="hp.footer.navigation.cooperate.chuangye"
                    >云+创业</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/developer/techo/review"
                    hotrep="hp.footer.navigation.cooperate.techo-developer"
                    >Techo 开发者大会
                  </a>
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/event/des2019"
                    hotrep="hp.footer.navigation.cooperate.des2019"
                    >腾讯数字生态大会</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/event/start.html"
                    hotrep="hp.footer.navigation.cooperate.yunqi_chanye"
                    >云启产业计划</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/event/innovationbase.html"
                    hotrep="hp.footer.navigation.cooperate.innovationbase"
                    >腾讯云启创新基地</a
                  >
                </li>
              </ul>
            </div>
          </li>

          <li class="c-g-5 xs-24">
            <div class="c-footer-website-group J-qcFooterNavColumn">
              <h3 class="c-footer-website-title J-qcFooterNavColumnTitle">
                <a href="javascript:;">管理与支持</a>
              </h3>

              <ul class="c-footer-website-list J-qcFooterNavColumnList">
                <li>
                  <a
                    href="https://cloud.tencent.com/announce"
                    hotrep="hp.footer.navigation.support.announce"
                    >公告</a
                  >
                </li>

                <li>
                  <a
                    href="https://console.cloud.tencent.com/"
                    hotrep="hp.footer.navigation.support.kongzhitai"
                    >控制台</a
                  >
                </li>

                <li>
                  <a
                    href="https://console.cloud.tencent.com/account/renewal"
                    hotrep="hp.footer.navigation.support.xufei"
                    >续费管理</a
                  >
                </li>

                <li>
                  <a
                    href="https://console.cloud.tencent.com/cam"
                    hotrep="hp.footer.navigation.support.fangwen"
                    >访问管理</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/services/security"
                    hotrep="hp.footer.navigation.support.security"
                    >安全中心</a
                  >
                </li>

                <li>
                  <a
                    href="https://console.cloud.tencent.com/workorder"
                    hotrep="hp.footer.navigation.support.gongdan"
                    >工单管理</a
                  >
                </li>

                <li>
                  <a
                    href="https://cloud.tencent.com/act/event/connect-service"
                    hotrep="hp.footer.navigation.support.lianxiwomen"
                    >联系我们</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="c-footer-copyright">
          <ul class="c-footer-share-links">
            <li class="J-qcFooterWXIcon">
              <div class="c-footer-bubble-wrap">
                <a href="javascript:;" title="微信" hotrep="hp.footer.sns.wx"
                  ><i class="icon wechat"></i
                ></a>
                <div
                  class="c-footer-share-bubble J-qcFooterWXBubble"
                  style="z-index: 0;"
                >
                  <div class="c-footer-share-bubble-inner">
                    <div class="bubble-qr no-pd">
                      <img
                        src="//imgcache.qq.com/open_proj/proj_qcloud_v2/gateway/portal/css/img/footer/wechat-assistant-qr.jpg"
                        alt=""
                      />
                    </div>
                    <p class="bubble-cnt">关注公众号，移动管理云服务</p>
                    <p class="bubble-cnt mobile">
                      长按识别或截图保存<br />关注公众号，移动管理云服务
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a
                href="http://weibo.com/qcloud2014"
                target="_blank"
                title="微博"
                hotrep="hp.footer.sns.weibo"
                ><i class="icon weibo"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/TencentCloud/"
                target="_blank"
                title="facebook"
                hotrep="hp.footer.sns.fb"
                ><i class="icon facebook"></i
              ></a>
            </li>
          </ul>

          <div class="c-hidden c-visible-xs mobile-part">
            <a href="tel:95716" class="c-btn" hotrep="hp.footer.mobile.tel"
              >购买咨询：95716 转 1</a
            >
            <a
              class="c-footer-tel-m"
              hotrep="hp.footer.mobile.connect"
              href="//cloud.tencent.com/about/connect"
              >联系我们</a
            >
            <div class="c-footer-select J-qcRegionSelector">
              <a
                href="javascript:;"
                class="c-footer-select-trigger J-qcRegionTrigger"
                ><i class="icon"></i
                ><span class="J-qcFooterRegion">中国站</span></a
              >

              <div class="c-footer-dropdown J-qcFooterRegionList">
                <ul class="c-footer-dropdown-menu">
                  <li class="actived">
                    <a
                      href="javascript:;"
                      class="J-qcRegionOption J-qcFooterSwitcher"
                      data-region="cn_zh"
                      hotrep="hp.footer.region.cn"
                      >中国站</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://intl.cloud.tencent.com"
                      class="J-qcRegionOption J-qcFooterSwitcher"
                      data-region="intl"
                      hotrep="hp.footer.region.intl"
                      >International</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p class="c-footer-copyright-text">
            <span>Copyright © 2013 - 2020</span>
            <span>Tencent Cloud. All Rights Reserved.</span>
            <span>腾讯云 版权所有</span>
          </p>

          <div class="c-hidden-xs pc-part">
            <a href="tel:95716" class="c-btn" hotrep="hp.footer.tel"
              >购买咨询：95716 转 1</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="c-footer-blogroll">
      <div class="c-footer-inner">
        <div class="c-footer-blogroll-main">
          <nav class="c-footer-blogroll-links">
            <a href="http://www.tencent.com/" target="_blank" class="icon-tc"
              ><span class="visually-hidden">tencent</span></a
            >

            <a
              href="http://open.qq.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.open"
              >腾讯开放平台</a
            >

            <a
              href="https://meeting.tencent.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.meeting"
              >腾讯会议</a
            >

            <a
              href="http://www.dnspod.cn/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.dnspod"
              >DNSPod</a
            >

            <a
              href="http://mp.weixin.qq.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.mp"
              >微信公众平台</a
            >

            <a
              href="http://open.youtu.qq.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.youtu"
              >腾讯优图</a
            >

            <a
              href="http://bk.tencent.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.lanjing"
              >腾讯蓝鲸</a
            >

            <a
              href="https://qidian.qq.com/?&amp;utm_source=relatedlinks&amp;utm_medium=tencentcloud&amp;utm_term=footer"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.qidian"
              >腾讯企点</a
            >

            <a
              href="http://www.weiyun.com/"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.weiyun"
              >腾讯微云</a
            >

            <a
              href="https://docs.qq.com/index.html?adtag=tencentcloud"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.docs"
              >腾讯文档</a
            >

            <a
              href="https://cloud.tencent.com/friendlink"
              target="_blank"
              rel="nofollow"
              class="slide"
              hotrep="hp.footer.flink.friendlink"
              >友情链接</a
            >

            <hr class="c-footer-blogroll-links-separate" />
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020287"
              target="_blank"
              rel="nofollow"
              class="slide slide-ct"
              hotrep="hp.footer.bottom.beian"
              >京公网安备 11010802017518</a
            >
            <a
              href="http://www.beian.miit.gov.cn"
              target="_blank"
              rel="nofollow"
              class="slide slide-ct"
              hotrep="hp.footer.bottom.miitbeian"
              >粤B2-20090059-1</a
            >
            <span class="slide slide-ct"
              >域名注册服务机构批复文号：京信信管发〔2018〕156号&nbsp;鲁通管〔2019〕83号</span
            >
            <span class="slide slide-ct"
              >代理域名注册服务机构：烟台帝思普网络科技有限公司（DNSPod）&nbsp;新网数码</span
            >
          </nav>
        </div>

        <div class="c-footer-blogroll-extra">
          <div class="c-footer-select J-qcRegionSelector">
            <a
              href="javascript:;"
              class="c-footer-select-trigger J-qcRegionTrigger"
              ><i class="icon"></i
              ><span class="J-qcFooterRegion">中国站</span></a
            >

            <div class="c-footer-dropdown J-qcFooterRegionList">
              <ul class="c-footer-dropdown-menu">
                <li class="actived">
                  <a
                    href="javascript:;"
                    class="J-qcRegionOption J-qcFooterSwitcher"
                    data-region="cn_zh"
                    hotrep="hp.footer.region.cn"
                    >中国站</a
                  >
                </li>
                <li>
                  <a
                    href="https://intl.cloud.tencent.com"
                    class="J-qcRegionOption J-qcFooterSwitcher"
                    data-region="intl"
                    hotrep="hp.footer.region.intl"
                    >International</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-box',
}
</script>
