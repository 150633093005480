import { getProperty } from '../util/objHandle.js'
import { getData } from 'requestModel'
import api from '../api/apiModule'

export const state = () => ({
  mainList: {
    total: 0,
    list: [],
  },
  partnersModuleList: {
    total: 0,
    list: [],
  },
  homeModuleList: [],
  chosenList: {
    total: 0,
    list: []
  },
})

export const actions = {
  async getMainList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getModuleList'],
      params: {
        actionType: 'MODULE_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setMainListData', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  async getPartnersModuleList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getModuleList'],
      params: {
        actionType: 'FRONT_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setPartnersModuleList', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
  // 首页数据
  async getHomeMainList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getModuleList'],
      params: {
        actionType: 'HOME_TOP_MODULE_LIST',
        ...params,
      },
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setHomeModuleList', ret.listData.list)
    } else {
      ret = null
    }

    return ret
  },
  async getChosenList({ commit }, params = {}) {
    let ret = await getData({
      api,
      dataset: ['getModuleList'],
      params: {
        ...params,
      },
    })

    if (getProperty(ret, 'listData.list', null)) {
      commit('setChosenModuleList', ret.listData)
    } else {
      ret = null
    }

    return ret
  },
}

export const mutations = {
  setMainListData(state, listData) {
    state.mainList.list = listData.list
    state.mainList.total = listData.total
  },
  setPartnersModuleList(state, listData) {
    state.partnersModuleList.list = listData.list
    state.partnersModuleList.total = listData.total
  },
  setHomeModuleList(state, listData) {
    state.homeModuleList = listData
  },
  setChosenModuleList(state, listData) {
    state.chosenList.total = listData.total
    state.chosenList.list = listData.list
  },
}
